<template>
  <div class="container">
    <el-card>
      <div slot="header" style="display: flex;">
        <div style="margin-right: 20px;">
          <i class="el-icon-location" style="margin-right: 5px; color: cornflowerblue;"></i>
          <span class="defaultfont" style="margin-right: 5px;">选择班级:</span>
          <el-select placeholder="请选择" v-model="NowClass">
            <el-option v-for="item in allClass" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div>
          <i class="el-icon-stopwatch" style="margin-right: 5px; color: cornflowerblue;"></i>
          <span class="defaultfont" style="margin-right: 5px;">学期选择:</span>
          <el-select v-model="leftSemester" placeholder="请选择">
            <el-option v-for="item in allSemester" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <i class="el-icon-s-promotion" style="margin-right: 5px; margin-left: 5px; color: cornflowerblue;"></i>
          <el-select v-model="rightSemester" placeholder="请选择" style="margin-right: 15px;">
            <el-option v-for="item in allSemester" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <el-button type="primary" round @click="clickTabButton">确定</el-button>
        </div>
      </div>
      <div ref="mains" style="width: auto;height: 300px"></div>
    </el-card>
  </div>
</template>

<script>
import { AllSemester, GetAllClass, GetSemester, GetClassScore } from "../../api/thing"

export default {
  name: "",
  data() {
    return {
      NowClass: 1,
      allClass: [],
      allSemester: [],
      leftSemester: 0,
      rightSemester: 0,
      source: [],
    };
  },
  methods: {
    async initInGrade() {
      try {
        await AllSemester().then(val => {
          this.allSemester = val.rows;
          GetSemester().then(value => {
            this.leftSemester = value.last.id;
            this.rightSemester = value.now.id;
            this.source[0] = ['学期', this.allSemester[this.leftSemester - 1].name, this.allSemester[this.rightSemester - 1].name]
          });
        });
        await GetAllClass().then(val => {
          this.allClass = val.rows;
        });
        this.queryClassScore();
      } catch (error) {
        console.error('错误:', error);
      }

    },
    clickTabButton() {
      this.source = [];
      this.source[0] = ['学期', this.allSemester[this.leftSemester - 1].name, this.allSemester[this.rightSemester - 1].name]
      this.queryClassScore();
    },
    queryClassScore() {
      GetClassScore(this.NowClass, this.leftSemester).then(val1 => {
        GetClassScore(this.NowClass, this.rightSemester).then(val2 => {
          this.transformObjects(val1.data, val2.data).forEach(item => {
            this.source.push(item)
          })
        })
      })
      this.initEcharts();
    },
    defaultOptions() {
      console.log(this.source)
      const option = {
        legend: {},
        tooltip: {
        },
        dataset: {
          source: this.source
        },
        xAxis: { type: 'category' },
        yAxis: {},
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [{
          type: 'bar',
          barWidth: '20%',
          label: {
            show: true, //开启显示
            position: 'top', //在上方显示
            formatter: function (name) {
              return name.data[1];
            },
            textStyle: { //数值样式
              color: 'black',//字体颜色
              fontSize: 10//字体大小
            },
          },
          itemStyle: {
            normal: {
              color: function (val) {
                if ((val.data[1] > 4.0 && val.data[1] < 5.0) || (val.data[1] > 85.0 && val.data[1] < 100.0)) {
                  return "#61a0a8"
                } else if ((val.data[1] > 3.0 && val.data[1] < 4.0) || (val.data[1] > 75.0 && val.data[1] < 85.0)) {
                  return '#FFFF66'
                } else {
                  return '#CC0000'
                }
              },
            }
          }
        }, {
          type: 'bar',
          barWidth: '20%',
          label: {
            show: true, //开启显示
            position: 'top', //在上方显示
            formatter: function (name) {
              return name.data[2];
            },
            textStyle: { //数值样式
              color: 'black',//字体颜色
              fontSize: 10//字体大小
            },
          },
          itemStyle: {
            normal: {
              color: function (val) {
                if ((val.data[2] >= 4.0 && val.data[2] <= 5.0) || (val.data[2] >= 85.0 && val.data[2] <= 100.0)) {
                  return "#61a0a8"
                } else if ((val.data[2] >= 3 && val.data[2] < 4) || (val.data[2] >= 75.0 && val.data[2] < 85.0)) {
                  return '#FFFF66'
                } else {
                  return '#CC0000'
                }
              },
            }
          }
        }],
        graphic: [
          {
            type: 'group',
            left: 'center',
            bottom: 10,
            children: [
              {
                type: 'text',
                z: 100,
                left: 'center',
                top: 'middle',
                style: {
                  fill: '#333',
                  text: "红色为平均分75或3分以下，黄色为平均分75或3分以上，蓝色为平均分85或4分以上",
                  font: '14px Microsoft YaHei'
                }
              }
            ]
          }
        ],
      };

      return option;
    },
    initEcharts() {
      this.chartInstance = this.$echarts.init(this.$refs.mains);
      this.chartInstance.showLoading();
      setTimeout(() => {
        this.chartInstance.setOption(this.defaultOptions(), true);
        this.chartInstance.hideLoading();
      }, 2000);
    },
    transformObjects(obj1, obj2) {
      console.log(obj1 + "1")
      console.log(obj2 + "2")
      if (obj1 == null) {
        return Object.keys(obj2).map(key => [key, 0, obj2[key].toFixed(1)]);
      }else if (obj2 == null) {
        return Object.keys(obj1).map(key => [key, obj1[key].toFixed(1), 0]);
      }else{
        return Object.keys(obj1).map(key => [key, obj1[key].toFixed(1), obj2[key].toFixed(1)]);
      }
      
    }
  },
  created() {
    this.initInGrade();

  }
};
</script>

<style scoped></style>